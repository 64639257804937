import axios from "axios";
import Swal from "sweetalert2";
import router from "../router/index";

const login = {
  state: {
    // tokenId: null,
    // username: null,
    email: null,
    // tier: 0,
    // active: 0,
  },
  mutations: {
    set_login_tokenId(state, token) {
      // state.tokenId = token;
      localStorage.setItem("tokenId", token);
    },
    // set_login_username(state, username) {
    //   state.username = username;
    // },
    set_login_email(state, email) {
      state.email = email;
    },
    // set_login_tier(state, tier) {
    //   state.tier = tier;
    // },
    // set_login_active(state, active) {
    //   state.active = active;
    // },
  },
  actions: {
    async login({ commit }, { username, password }) {
      Swal.fire({
        allowEscapeKey: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        title: "Loading.....",
        text: "กรุณารอสักครู่",
      });

      try {

       

        const res = await axios.post(
          `${process.env.VUE_APP_BACKEND}/mysqlapi/login`,
          {
            username: username,
            password: password,
          }
        );

        if (res.data.status === 1) {
          commit("set_login_tokenId", res.data.token);
          // commit("set_login_username", res.data.username);
          commit("set_login_email", res.data.email);
          // commit("set_login_tier", res.data.status.tier);
          // commit("set_login_active", res.data.status.active);
          // const ip = await axios.get(`https://api.ipify.org/?format=json`);

          // axios.post(`${process.env.VUE_APP_BACKEND}/mysqlapi/mail/login`, {
          //   username: res.data.username,
          //   email: res.data.email,
          //   ip: ip.data.ip
          // });

          router.push({ name: "Home" });
        }

        Swal.fire({
          showConfirmButton: false,
          title: res.data.title,
          text: res.data.text,
          icon: res.data.icon,
          timer: res.data.timer,
        });
        // else {
        //   Swal.fire({
        //     title: "กรุณายืนยันตัวตน",
        //     text: "Verify Email",
        //     icon: "warning",
        //     timer: 3000,
        //   });
        // }

        // console.log(res);
      } catch (error) {
        // console.log(error);
        Swal.fire({
          allowEscapeKey: true,
          showConfirmButton: false,
          allowOutsideClick: true,
          title: "เกิดข้อผิดพลาด",
          icon: "error",
          text: error,
        });
      }
    },
  },
  getters: {
    // get_login_username(state) {
    //   return state.username;
    // },
    get_login_email(state) {
      return state.email;
    },
    // get_login_tier(state) {
    //   return state.tier;
    // },
    // get_login_active(state) {
    //   return state.active;
    // },
  },
  modules: {},
};

export default login;

<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100 p-3">
        <h1>จดบันทึก</h1>
        <hr />
        <div class="row">
          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-label">หุ้น</label>
              <input
                v-model="stock"
                ref="stock"
                type="text"
                class="form-control"
                placeholder="กรอกชื่อหุ้น"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">หัวข้อ</label>
              <input
                v-model="title"
                ref="title"
                type="text"
                class="form-control"
                placeholder="กรอกหัวข้อ"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">ข้อความ</label>
              <textarea
                ref="detail"
                v-model="detail"
                class="form-control"
                rows="3"
                placeholder="กรอกข้อความ"
              ></textarea>
            </div>
            <div class="mb-3">
              <div class="d-grid gap-2 d-md-block">
                <button class="btn btn-primary" @click="save" type="button">
                  บันทึก
                </button>
                <span class="p-2"></span>
                <button class="btn btn-danger" @click="reset" type="button">
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="table-responsive">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">หุ้น</th>
                    <th scope="col" class="text-center">หัวข้อ</th>
                    <th scope="col" class="text-center">รายละเอียด</th>
                    <th scope="col" class="text-center">เวลาบันทึก</th>
                    <th scope="col" class="text-center">จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in this.$store.getters
                      .get_stock_note_data"
                    :key="index"
                  >
                    <td class="text-center">
                      <a href="#" @click="stock_search(data.stock)">
                        {{ data.stock }}
                      </a>
                    </td>
                    <td class="text-center">
                      {{ data.title }}
                    </td>
                    <td>
                      {{ data.detail }}
                    </td>
                    <td class="text-center">
                      {{ data.timesave }}
                    </td>
                    <td class="text-center">
                      <button @click="del(data._id)" class="btn btn-danger">
                        ลบ
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- <hr />
        <div>
          {{ this.$store.getters.get_stock_note_data }}
        </div> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";

import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Note",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    this.$store.dispatch("load_stock_note_data");
    this.stock = this.$store.getters.get_stock_name_data;
    this.$refs.title.focus();
  },
  data() {
    return {
      stock: "",
      title: "",
      detail: "",
    };
  },
  methods: {
    stock_search(s) {
      this.$router.push({ name: "Stock" });
      this.$store.dispatch("reset_stock_state");
      this.$store.dispatch("load_stock_company_profile", s);
      this.$store.dispatch("load_stock_name_data", s);
      this.$store.dispatch("load_stock_quote_data", s);
      this.$store.dispatch("load_stock_rightsandbenefit_data", s);
      this.$store.dispatch("load_stock_historical_data", s);
      this.$store.dispatch("load_stock_financial_data", s);
      this.$store.dispatch("load_stock_data_success_data");
    },
    save() {
      moment.locale("th");
      let date = moment().add(543, "year").format("LLLL");

      if (
        this.title.trim() === "" ||
        this.detail.trim() === "" ||
        this.stock.trim() === ""
      ) {
        Swal.fire({
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          icon: "error",
          timer: 1200,
          showConfirmButton: false,
        });

        setTimeout(() => {
          if (this.title.trim() === "") {
            this.$refs.title.focus();
          } else if (this.detail.trim() === "") {
            this.$refs.detail.focus();
          } else if (this.stock.trim() === "") {
            this.$refs.stock.focus();
          }
        }, 1500);
      } else {
        Swal.fire({
          title: "คุณต้องการบันทึกข้อความนี้",
          text: "บันทึกข้อมูล",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "บันทึกข้อมูล",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("load_stock_save_note_data", {
              stock: this.stock.trim(),
              title: this.title.trim(),
              detail: this.detail.trim(),
              timesave: date,
            });
            this.stock = "";
            this.title = "";
            this.detail = "";
          }
        });
      }

      // console.log(data);
    },
    del(id) {
      // console.log(id);
      Swal.fire({
        title: "คุณต้องการลบข้อมูล",
        text: "ลบข้อมูล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ใช่, ลบข้อมูล",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("load_stock_del_note_data", id);
        }
      });
    },
    reset() {
      this.title = "";
      this.detail = "";
    },
  },
};
</script>

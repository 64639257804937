<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100">
        <div class="row mb-2">
          <h1>
            รายงาน XD
            <small>เดือน {{ this.$store.getters.get_stock_month_thai }}</small>
          </h1>
        </div>

        <div class="row mb-2">
          <div class="col-md-2 mr-3">
            <div class="d-grid">
              <button class="btn btn-primary mt-2" @click="select(0)">
                {{ method_month_thai(0) }}
              </button>
            </div>
          </div>
          <div class="col-md-2 mr-3">
            <div class="d-grid">
              <button class="btn btn-primary mt-2" @click="select(1)">
                {{ method_month_thai(1) }}
              </button>
            </div>
          </div>
          <div class="col-md-2 mr-3">
            <div class="d-grid">
              <button class="btn btn-primary mt-2" @click="select(2)">
                {{ method_month_thai(2) }}
              </button>
            </div>
          </div>
          <div class="col-md-2 mr-3">
            <div class="d-grid">
              <button class="btn btn-primary mt-2" @click="select(3)">
                {{ method_month_thai(3) }}
              </button>
            </div>
          </div>
          <div class="col-md-2 mr-3">
            <div class="d-grid">
              <button class="btn btn-primary mt-2" @click="select(4)">
                {{ method_month_thai(4) }}
              </button>
            </div>
          </div>
          <div class="col-md-2 mr-3">
            <div class="d-grid">
              <button class="btn btn-primary mt-2" @click="select(5)">
                {{ method_month_thai(5) }}
              </button>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead></thead>
              <tbody>
                <tr
                  v-for="(row, index) in this.$store.getters
                    .get_stock_reportXD_data"
                  :key="index"
                >
                  <td style="width: 50px;">Day</td>
                  <td
                    style="width: 50px;"
                    v-for="(col, index) in row"
                    :key="index"
                  >
                    <span v-if="number(col)">{{ col }}</span>
                    <a href="#" @click="stock(col)" v-else>
                      {{ col }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <hr />
        <div>
          {{ this.$store.getters.get_stock_report_data }}
        </div> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: "Report",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    // this.$store.dispatch("reset_stock_state");
    if (this.$store.getters.get_stock_reportXD_data.length === 0) {
      this.$store.dispatch(
        "load_stock_reportXD_data",
        this.$store.getters.get_stock_xd_id_data
      );
      this.$store.dispatch(
        "load_stock_month_thai",
        this.method_month_thai(this.$store.getters.get_stock_xd_id_data)
      );
    }
  },
  data() {
    return {
      month: new Date(),
    };
  },
  methods: {
    select(id) {
      this.$store.dispatch("reset_stock_report_xd_state");
      this.$store.dispatch("load_stock_reportXD_data", id);
      this.$store.dispatch("load_stock_month_thai", this.method_month_thai(id));
    },
    method_month_thai(id) {
      id += this.month.getMonth() - 1;

      if(id === 12) {
        id = 0;
      } else if(id === 13) {
        id = 1;
      } else if(id === 14) {
        id = 2;
      } else if(id === 15) {
        id = 3;
      } else if(id === 16){
        id = 4;
      } else if(id === 17) {
        id = 5;
      }

      // console.log(id)

      switch (id) {
        case 0:
          return "มกราคม";
        case 1:
          return "กุมภาพันธ์";
        case 2:
          return "มีนาคม";
        case 3:
          return "เมษายน";
        case 4:
          return "พฤษภาคม";
        case 5:
          return "มิถุนายน";
        case 6:
          return "กรกฎาคม";
        case 7:
          return "สิงหาคม";
        case 8:
          return "กันยายน";
        case 9:
          return "ตุลาคม";
        case 10:
          return "พฤศจิกายน";
        case 11:
        case -1:
          return "ธันวาคม";
        default:
          return "เกิน";
      }
    },
    method_month_check() {
      this.id = this.month.getMonth();
    },
    stock(s) {
      this.$router.push({ name: "Stock" });
      this.$store.dispatch("reset_stock_state");
      this.$store.dispatch("load_stock_company_profile", s);
      this.$store.dispatch("load_stock_name_data", s);
      this.$store.dispatch("load_stock_quote_data", s);
      this.$store.dispatch("load_stock_rightsandbenefit_data", s);
      this.$store.dispatch("load_stock_historical_data", s);
      this.$store.dispatch("load_stock_financial_data", s);
      this.$store.dispatch("load_stock_data_success_data");
    },
    number(n) {
      if (
        n === "1" ||
        n === "2" ||
        n === "3" ||
        n === "4" ||
        n === "5" ||
        n === "6" ||
        n === "7" ||
        n === "8" ||
        n === "9" ||
        n === "10" ||
        n === "11" ||
        n === "12" ||
        n === "13" ||
        n === "14" ||
        n === "15" ||
        n === "16" ||
        n === "17" ||
        n === "18" ||
        n === "19" ||
        n === "20" ||
        n === "21" ||
        n === "22" ||
        n === "23" ||
        n === "24" ||
        n === "25" ||
        n === "26" ||
        n === "27" ||
        n === "28" ||
        n === "29" ||
        n === "30" ||
        n === "31"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="text-center">
        <img
          class="d-block mx-auto mb-4"
          src="../assets/img/android-chrome-512x512.png"
          width="120"
          height="100"
        />
        <h2>สมัครสมาชิก</h2>
        <b> กรุณากรอกข้อมูลการสมาชิกเข้าใช้งาน </b><br />
      </div>
    </div>
    <div class="row mb-3">
      <h4 class="mb-3">ข้อมูลใช้งาน <small class="text-danger">*</small></h4>
      <div class="col-md-6">
        <label class="form-label mt-2"
          >ชื่อผู้ใช้ (Username) <small class="text-danger">*</small></label
        >
        <div class="input-group">
          <span class="input-group-text"><i class="far fa-user"></i></span>
          <input
            v-model="this.$store.getters.get_register_state.username"
            type="text"
            class="form-control"
            ref="username"
            placeholder="Username"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label mt-2"
          >อีเมล (Email) <small class="text-danger">*</small></label
        >
        <div class="input-group">
          <span class="input-group-text"><i class="far fa-envelope"></i></span>
          <input
            v-model="this.$store.getters.get_register_state.email"
            type="email"
            class="form-control"
            ref="email"
            placeholder="you@example.com"
          />
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-6">
        <label class="form-label mt-2"
          >รหัสผ่าน (Password) <small class="text-danger">*</small></label
        >
        <div class="input-group">
          <span class="input-group-text"><i class="fas fa-key"></i></span>
          <input
            v-model="this.$store.getters.get_register_state.password"
            type="password"
            class="form-control"
            ref="password"
            placeholder="Password"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label mt-2"
          >ยืนยันรหัสผ่าน (Comfirm Password)
          <small class="text-danger">*</small>
        </label>
        <div class="input-group">
          <span class="input-group-text"><i class="fas fa-key"></i></span>
          <input
            v-model="this.$store.getters.get_register_state.passwordconfirm"
            type="password"
            class="form-control"
            ref="passwordconfirm"
            placeholder="Password Confirm"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-check">
          <input
            v-model="check_policy"
            class="form-check-input"
            type="checkbox"
          />
          <label class="form-check-label" for="flexCheckChecked">
            ยอมรับนโยบายความเป็นส่วนตัว
            <a href="/policy" target="_blank">Privacy Policy</a>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <button
          @click="register"
          class="btn btn-primary btn-width100 mt-2"
          type="button"
        >
          Register Now (สมัคร)
        </button>
      </div>
      <div class="col-md-4">
        <button
          @click="goback"
          class="btn btn-danger btn-width100 mt-2"
          type="button"
        >
          Go Back (ย้อนกลับ)
        </button>
      </div>
      <div class="col-md-4">
        <button
          @click="register_resetstate"
          class="btn btn-warning btn-width100 mt-2"
          type="button"
        >
          Reset (ล้างข้อมูล)
        </button>
      </div>
    </div>
    <!-- <hr />
    <div class="row mb-3">
      <h4 class="mb-3">
        ข้อมูลการติดต่อ
      </h4>
      <div class="col-md-4">
        <label class="form-label mt-2">คำนำหน้าชื่อ (Title Name)</label>
        <input
          v-model="this.$store.getters.get_register_state.titlename"
          type="text"
          class="form-control"
          ref="inputTitlename"
          placeholder="Title Name"
          required
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2">ชื่อ (First Name)</label>
        <input
          v-model="this.$store.getters.get_register_state.firstname"
          type="text"
          class="form-control"
          ref="inputFirstname"
          placeholder="First Name"
          required
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2">นามสกุล (Last Name)</label>
        <input
          v-model="this.$store.getters.get_register_state.lastname"
          type="text"
          class="form-control"
          ref="inputLastname"
          placeholder="Last Name"
          required
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <label class="form-label mt-2">เลขที่บ้าน (Home Number)</label>
        <input
          v-model="this.$store.getters.get_register_state.number"
          type="text"
          class="form-control"
          ref="number"
          placeholder="ตัวอย่าง 18/6"
          required
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2">หมู่ / ตรอก / ซอย / หมู่บ้าน</label>
        <input
          v-model="this.$store.getters.get_register_state.detail"
          type="text"
          class="form-control"
          ref="detail"
          placeholder="ตัวอย่าง หมู่ 1 ซอย 8 หมู่บ้านเทพราช"
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2">ถนน (Road)</label>
        <input
          v-model="this.$store.getters.get_register_state.road"
          type="text"
          class="form-control"
          ref="detail"
          placeholder="ตัวอย่าง ถนน ราเมศวร"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <label class="form-label mt-2">ตำบล (subdistrict)</label>
        <input
          v-model="this.$store.getters.get_register_state.subdistrict"
          type="text"
          class="form-control"
          ref="subdistrict"
          placeholder="ตัวอย่าง ตำบล เขาสามยอด"
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2">อำเภอ (district)</label>
        <input
          v-model="this.$store.getters.get_register_state.district"
          type="text"
          class="form-control"
          ref="district"
          placeholder="ตัวอย่าง อำเภอ เมือง"
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2">จังหวัด (province)</label>
        <input
          v-model="this.$store.getters.get_register_state.province"
          type="text"
          class="form-control"
          ref="province"
          placeholder="ตัวอย่าง จังหวัด พิษณุโลก"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <label class="form-label mt-2">รหัสไปรษณีย์ (zipcode)</label>
        <input
          v-model="this.$store.getters.get_register_state.zipcode"
          type="text"
          class="form-control"
          ref="zipcode"
          placeholder="ตัวอย่าง 15000"
        />
      </div>
    </div>
    <hr />
    <div class="row mb-3">
      <div class="col-md-4">
        <label class="form-label mt-2"
          ><i class="fas fa-phone"></i> โทรศัพท์บ้าน (telephone)</label
        >
        <input
          v-model="this.$store.getters.get_register_state.telephone"
          type="text"
          class="form-control"
          ref="telephone"
          placeholder="ตัวอย่าง 036423032"
          required
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2"
          ><i class="fas fa-mobile-alt"></i> โทรศัพท์มือถือ (mobilephone)</label
        >
        <input
          v-model="this.$store.getters.get_register_state.mobilephone"
          type="text"
          class="form-control"
          ref="mobilephone"
          placeholder="ตัวอย่าง 0805151651"
          required
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2"
          ><i class="fas fa-fax"></i> แฟกซ์ (fax)</label
        >
        <input
          v-model="this.$store.getters.get_register_state.faxphone"
          type="text"
          class="form-control"
          ref="faxphone"
          placeholder="ตัวอย่าง 0805151651"
          required
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <label class="form-label mt-2"
          ><i class="fab fa-facebook-square"></i> เฟสบุ๊ค (facebook)</label
        >
        <input
          v-model="this.$store.getters.get_register_state.facebook"
          type="text"
          class="form-control"
          ref="facebook"
          placeholder="ตัวอย่าง Morakot Thongprom"
          required
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2"
          ><i class="fab fa-youtube"></i> ยูทูป (youtube)</label
        >
        <input
          v-model="this.$store.getters.get_register_state.youtube"
          type="text"
          class="form-control"
          ref="youtube"
          placeholder="ตัวอย่าง tonmorakot"
          required
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2"
          ><i class="fab fa-instagram"></i> อินตราแกรม (instagram)</label
        >
        <input
          v-model="this.$store.getters.get_register_state.instagram"
          type="text"
          class="form-control"
          ref="instagram"
          placeholder="ตัวอย่าง tonmorakot"
          required
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <label class="form-label mt-2"
          ><i class="fab fa-twitter"></i> ทวิตเตอร์ (Twitter)</label
        >
        <input
          v-model="this.$store.getters.get_register_state.twitter"
          type="text"
          class="form-control"
          ref="twitter"
          placeholder="ตัวอย่าง @tonmorakot"
          required
        />
      </div>
      <div class="col-md-4">
        <label class="form-label mt-2"
          ><i class="fab fa-line"></i> ไลน์ (Line ID)</label
        >
        <input
          v-model="this.$store.getters.get_register_state.line"
          type="text"
          class="form-control"
          ref="line"
          placeholder="ตัวอย่าง ton_morakot"
          required
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <button
          @click="register"
          class="btn btn-primary btn-width100 mt-2"
          type="button"
        >
          Register Now (สมัครทันที)
        </button>
      </div>
      <div class="col-md-4">
        <button
          @click="goback"
          class="btn btn-danger btn-width100 mt-2"
          type="button"
        >
          Go Back (ย้อนกลับ)
        </button>
      </div>
      <div class="col-md-4">
        <button
          @click="checkstate"
          class="btn btn-warning btn-width100 mt-2"
          type="button"
        >
          Check State
        </button>
      </div>
    </div> -->

    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";

export default {
  components: {
    Footer,
  },
  mounted() {
    this.$refs.username.focus();
    // this.email = this.$store.getters.get_register_state.email;
    // this.password = this.$store.getters.get_register_state.password;
    // this.username = this.$store.getters.get_register_state.username;
    // this.titlename = this.$store.getters.get_register_state.titlename;
    // this.firstname = this.$store.getters.get_register_state.firstname;
    // this.lastname = this.$store.getters.get_register_state.lastname;
    // this.number = this.$store.getters.get_register_state.number;
    // this.detail = this.$store.getters.get_register_state.detail;
    // this.road = this.$store.getters.get_register_state.road;
    // this.subdistrict = this.$store.getters.get_register_state.subdistrict;
    // this.district = this.$store.getters.get_register_state.district;
    // this.province = this.$store.getters.get_register_state.province;
    // this.zipcode = this.$store.getters.get_register_state.zipcode;
    // this.workphone = this.$store.getters.get_register_state.workphone;
    // this.telephone = this.$store.getters.get_register_state.telephone;
    // this.mobilephone = this.$store.getters.get_register_state.mobilephone;
    // this.faxphone = this.$store.getters.get_register_state.faxphone;
    // this.facebook = this.$store.getters.get_register_state.facebook;
    // this.youtube = this.$store.getters.get_register_state.youtube;
    // this.instagram = this.$store.getters.get_register_state.instagram;
    // this.twitter = this.$store.getters.get_register_state.twitter;
    // this.line = this.$store.getters.get_register_state.line;
  },
  data() {
    return {
      check_policy: false,
    };
  },
  methods: {
    async goback() {
      this.$router.push({ name: "Login" });
    },
    async register() {
      if (this.$store.getters.get_register_state.username.trim() === "") {
        let alert = await {
          title: "ข้อมูลไม่ครบถ้วน",
          text: "กรุณากรอกชื่อผู้ใช้",
          icon: "error",
          timer: 1500,
        };

        await this.$store.getters.alertSystem(alert);

        setTimeout(() => {
          this.$refs.username.focus();
        }, 1800);
      } else if (this.$store.getters.get_register_state.email.trim() === "") {
        let alert = await {
          title: "ข้อมูลไม่ครบถ้วน",
          text: "กรุณากรอกอีเมล",
          icon: "error",
          timer: 1500,
        };

        await this.$store.getters.alertSystem(alert);

        setTimeout(() => {
          this.$refs.email.focus();
        }, 1800);
      } else if (
        this.$store.getters.get_register_state.password.trim() === ""
      ) {
        let alert = await {
          title: "ข้อมูลไม่ครบถ้วน",
          text: "กรุณากรอกรหัสผ่าน",
          icon: "error",
          timer: 1500,
        };

        await this.$store.getters.alertSystem(alert);

        setTimeout(() => {
          this.$refs.password.focus();
        }, 1800);
      } else if (
        this.$store.getters.get_register_state.passwordconfirm.trim() === ""
      ) {
        let alert = await {
          title: "ข้อมูลไม่ครบถ้วน",
          text: "กรุณายืนยันรหัสผ่าน",
          icon: "error",
          timer: 1500,
        };

        await this.$store.getters.alertSystem(alert);

        setTimeout(() => {
          this.$refs.passwordconfirm.focus();
        }, 1800);
      } else if (
        !this.$store.getters.emailCheck(
          this.$store.getters.get_register_state.email
        )
      ) {
        let alert = await {
          title: "รูปแบบอีเมลไม่ถูกต้อง",
          text: "กรุณาแก้ไข",
          icon: "error",
          timer: 1500,
        };

        await this.$store.getters.alertSystem(alert);
        setTimeout(() => {
          this.$refs.email.focus();
        }, 1800);
      } else if (this.check_policy === false) {
        let alert = await {
          title: "กรุณากดยอมรับ",
          text: "กรุณากดยอมรับนโยบายความเป็นส่วนตัว",
          icon: "error",
          timer: 1500,
        };

        await this.$store.getters.alertSystem(alert);
      } else {
        if (
          this.$store.getters.get_register_state.password ===
          this.$store.getters.get_register_state.passwordconfirm
        ) {
          await this.$store.dispatch("register_state");
        } else {
          let alert = await {
            title: "รหัสผ่านไม่ตรงกัน",
            text: "กรุณาแก้ไขรหัสผ่าน",
            icon: "error",
            timer: 1500,
          };

          await this.$store.getters.alertSystem(alert);
          setTimeout(() => {
            this.$refs.passwordconfirm.focus();
          }, 1800);
        }
      }
    },
    async register_resetstate() {
      await this.$store.dispatch("register_resetstate");
      this.passwordconfirm = "";
      this.$refs.username.focus();
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100">
        <h4 class="mb-3">บันทึกข้อมูลคะแนน</h4>
        <div class="table-responsive">
          <table class="table table-light table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">รหัสนักศึกษา</th>
                <th scope="col">ชื่อ - นามสกุล</th>
                <th scope="col">ข้อมูลคะแนน</th>
                <th scope="col">โทร</th>
                <th scope="col">จัดการข้อมูล</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, index) in this.$store.getters.get_user"
                :key="index"
              >
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <div
                    v-for="(addresse, index) in user.aim_addresses"
                    :key="index"
                  >
                    {{ addresse.studentid || "ไม่พบข้อมูล" }}
                  </div>
                </td>
                <td>
                  <div
                    v-for="(addresse, index) in user.aim_addresses"
                    :key="index"
                  >
                    {{ addresse.firstname || "ไม่พบข้อมูล" }}
                    {{ addresse.lastname || "ไม่พบข้อมูล" }}
                  </div>
                </td>
                <td>
                  <div
                    v-for="(addresse, index) in user.aim_addresses"
                    :key="index"
                  >
                    {{ addresse.comment || "ไม่พบข้อมูล" }}
                  </div>
                </td>
                <td>
                  <div v-for="(social, index) in user.aim_socials" :key="index">
                    {{ social.mobilephone || "ไม่พบข้อมูล" }}
                  </div>
                </td>
                <td>
                  <span
                    v-for="(addresse, index) in user.aim_addresses"
                    :key="index"
                  >
                    <button
                      @click="save(user.id, addresse.studentid)"
                      class="btn btn-primary"
                    >
                      บันทึก
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "../components/Footer.vue";
import moment from "moment";

import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "PointStudent",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    //if (!this.$store.getters.get_user) {
    this.$store.dispatch("LoadUsers");
    //}
  },
  methods: {
    async save(id, studentid) {
      // console.log(id);
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: `บันทึกข้อมูลคะแนน รหัส ${studentid} `,
        inputPlaceholder: "กรอกข้อมูล",
        showCancelButton: true,
      });

      if (text) {
        Swal.fire(text);
        const token = localStorage.getItem("tokenId");

        Swal.fire({
          allowEscapeKey: true,
          showConfirmButton: false,
          allowOutsideClick: false,
          title: "Loading.....",
          text: "กรุณารอสักครู่",
        });

        try {
          const res = await axios.post(
            `${process.env.VUE_APP_BACKEND}/mysqlapi/editcomment`,
            {
              token: token,
              data: text,
              id,
            }
          );

          Swal.fire({
            title: res.data.title,
            text: res.data.text,
            icon: res.data.icon,
            showConfirmButton: false,
            timer: res.data.timer,
          });

          this.$store.dispatch("LoadUsers");
          // console.log(res);
        } catch (error) {
          console.log(error);
        }
      }
    },
    dateTime(Time) {
      return moment(Time).format("DD-MM-YYYY MM:mm:ss ZZ");
    },
  },
};
</script>

<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <div class="margin-top-100">
        <div class="row">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <span class="input-group-text">หุ้น</span>
              <input
                v-on:keyup.enter="loadStock"
                v-model="stock"
                ref="inputStock"
                type="text"
                class="form-control"
                placeholder="หุ้น"
              />
              <button
                @click="loadStock"
                class="btn btn-outline-success"
                type="button"
              >
                ตรวจสอบ
              </button>
              <a
                v-if="this.$store.getters.get_stock_data_success_data"
                @click="url()"
                class="btn btn-outline-dark"
                >เพิ่มเติม</a
              >
              <a
                v-if="this.$store.getters.get_stock_data_success_data"
                :href="url2()"
                target="_blank"
                class="btn btn-outline-primary"
              >
                ดูกราฟ
              </a>
            </div>
          </div>
          <div class="col-md-6">
            <button
              v-if="this.$store.getters.get_stock_data_success_data"
              class="btn btn-success"
              @click="save"
            >
              บันทึก
            </button>
            <span class="p-2"></span>
            <router-link to="/stock/note" class="btn btn-info text-white"
              >จดบันทึก</router-link
            >
            <span class="p-2"></span>
            <router-link to="/stock/report" class="btn btn-primary"
              >รายงานหุ้นปันผล</router-link
            >
            <span class="p-2"></span>
            <router-link to="/stock/report/xd" class="btn btn-warning"
              >รายงาน XD</router-link
            >
          </div>
        </div>

        <div
          class="mt-3"
          v-if="
            this.$store.getters.get_stock_data_success_data &&
              this.$store.getters.get_stock_financial_data.length > 0 &&
              this.$store.getters.get_stock_rightsandbenefit_summary_data
                .length > 0
          "
        >
          <div class="row">
            <div class="col-md-4">
              <div class="card border-primary mb-3">
                <div class="card-header"><b>ข้อมูลบริษัท</b></div>
                <div class="card-body text-primary">
                  <p class="card-text">
                    <a href="#" @click="company_google()">
                      <span
                        v-for="(company, index) in this.$store.getters
                          .get_stock_company_profile[0]"
                        :key="index"
                        ><span v-if="company !== `ชื่อบริษัท`"
                          >{{ company }}&nbsp;</span
                        ></span
                      ></a
                    >
                    <br />
                    <span
                      v-for="(company, index) in this.$store.getters
                        .get_stock_company_profile[4]"
                      :key="index"
                      ><span v-if="index < 2"
                        ><span v-if="index === 1"
                          ><a :href="url_company(company)" target="_blank">{{
                            company
                          }}</a
                          >&nbsp;</span
                        ><span v-else>{{ company }}&nbsp;</span></span
                      ></span
                    ><br />
                    <span
                      v-for="(company, index) in this.$store.getters
                        .get_stock_company_profile[8]"
                      :key="index"
                      ><span>{{ company }}&nbsp;</span></span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card border-primary mb-3">
                <div class="card-header"><b>ข้อมูลธุรกิจ</b></div>
                <div class="card-body text-primary">
                  <p class="card-text">
                    <span
                      v-for="(company, index) in this.$store.getters
                        .get_stock_company_profile[5]"
                      :key="index"
                      ><span>{{ company }}&nbsp;</span></span
                    ><br />
                    <span
                      v-for="(company, index) in this.$store.getters
                        .get_stock_company_profile[6]"
                      :key="index"
                      ><span>{{ company }}&nbsp;</span></span
                    ><br />
                    <span
                      v-for="(company, index) in this.$store.getters
                        .get_stock_company_profile[7]"
                      :key="index"
                      ><span>{{ company }}&nbsp;</span></span
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card border-primary mb-3">
                <div class="card-header"><b>ที่อยู่</b></div>
                <div class="card-body text-primary">
                  <p class="card-text">
                    <span
                      v-for="(company, index) in this.$store.getters
                        .get_stock_company_profile[1]"
                      :key="index"
                      ><span v-if="company !== `ที่อยู่`"
                        >{{ company }}&nbsp;</span
                      ></span
                    ><br />
                    <span
                      v-for="(company, index) in this.$store.getters
                        .get_stock_company_profile[2]"
                      :key="index"
                      ><span>{{ company }}&nbsp;</span></span
                    >
                    <span
                      v-for="(company, index) in this.$store.getters
                        .get_stock_company_profile[3]"
                      :key="index"
                      ><span>{{ company }}&nbsp;</span></span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>

          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">ราคา</th>
                <th scope="col">ราคาที่ควรซื้อ</th>
                <th scope="col">ควรซื้อ</th>
                <th scope="col">Dividend</th>
                <th scope="col">เฉลี่ยปันผล</th>
                <th scope="col">SD</th>
                <th scope="col">พิสัย</th>
                <th scope="col">วันที่ XD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ this.$store.getters.get_stock_last_quote_data }}
                  ({{
                    (
                      (this.$store.getters.get_stock_last_quote_data /
                        (this.$store.getters.get_stock_historical_data.length >
                        0
                          ? this.$store.getters.get_stock_historical_data[
                              this.$store.getters.get_stock_historical_data
                                .length - 1
                            ][5]
                          : 0)) *
                        100 -
                      100
                    ).toFixed(2)
                  }}
                  %)
                </td>
                <td>
                  {{
                    (
                      +this.$store.getters
                        .get_stock_rightsandbenefit_summary_div_avg_data / 0.07
                    ).toFixed(2)
                  }}
                </td>
                <td>
                  {{
                    (
                      (+this.$store.getters
                        .get_stock_rightsandbenefit_summary_div_avg_data /
                        0.07 /
                        this.$store.getters.get_stock_quote_detail_data.Prior) *
                      100
                    ).toFixed(2)
                  }}
                  %
                </td>
                <td>
                  {{
                    (
                      (+this.$store.getters
                        .get_stock_rightsandbenefit_summary_div_avg_data /
                        +this.$store.getters.get_stock_quote_detail_data
                          .Prior) *
                      100
                    ).toFixed(2)
                  }}
                  %
                </td>
                <td>
                  {{
                    this.$store.getters.get_stock_rightsandbenefit_summary_div_avg_data.toFixed(
                      5
                    )
                  }}
                  ({{
                    this.$store.getters.get_stock_rightsandbenefit_summary_data
                      .length
                  }}
                  ปีย้อนหลัง)
                </td>
                <td>
                  {{
                    this.$store.getters.get_stock_rightsandbenefit_sd.toFixed(
                      5
                    ) || "Loading"
                  }}
                </td>
                <td>
                  {{
                    this.$store.getters.get_stock_rightsandbenefit_r.toFixed(5)
                  }}
                </td>
                <td>{{ this.$store.getters.get_stock_date_xd }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">ปี</th>
                <th scope="col">ปันผล (ต่อหุ้น)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in this.$store.getters
                  .get_stock_rightsandbenefit_summary_data"
                :key="index"
              >
                <td>{{ +("20" + data.year) + 543 }}</td>
                <td>{{ data.div.toFixed(5) }}</td>
              </tr>
            </tbody>
          </table>
          <!-- {{ this.$store.getters.get_stock_rightsandbenefit_summary_data }} -->

          <div>
            <canvas id="myChart" width="100%" height="250"></canvas>
          </div>

          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col" class="small">วันที่</th>
                  <th scope="col" class="small">ราคาเปิด</th>
                  <th scope="col" class="small">ราคาสูงสุด</th>
                  <th scope="col" class="small">ราคาต่ำสุด</th>
                  <th scope="col" class="small">ราคาเฉลี่ย</th>
                  <th scope="col" class="small">ราคาปิด</th>
                  <th scope="col" class="small">เปลี่ยนแปลง</th>
                  <th scope="col" class="small">%เปลี่ยนแปลง</th>
                  <th scope="col" class="small">ปริมาณหุ้น (พันหุ้น)</th>
                  <th scope="col" class="small">มูลค่า (ล้านบาท)</th>
                  <th scope="col" class="small">SET Index</th>
                  <th scope="col" class="small">%เปลี่ยนแปลง</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in this.$store.getters
                    .get_stock_historical_data"
                  :key="index"
                >
                  <td v-for="(col, index) in row" :key="index">{{ col }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "../components/Footer.vue";

import Swal from "sweetalert2";
import Chart from "chart.js";

export default {
  name: "Stock",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    // this.data_success = false;
    // this.$refs.inputStock.focus();
    this.$refs.inputStock.select();
    this.stock = this.$store.getters.get_stock_name_data;

    this.timeout_autosave = setTimeout(() => {
      this.autosave();
    }, 2000);

    // console.log(this.$store.getters
    //                 .get_stock_historical_data[119][5]);
  },
  beforeUnmount() {
    // console.log("beforeDestroy");
    clearTimeout(this.timeout_autosave);
  },
  data() {
    return {
      // data_success: true,
      stock: "",
      timeout_autosave: null,
      datacollection: null,
    };
  },
  methods: {
    company_google() {
      let data = this.$store.getters.get_stock_company_profile[0]
        .join(" ")
        .replace("ชื่อบริษัท ", "");

      // console.log(data);

      window.open(`https://www.google.com/search?q=${data}`);
    },
    url_company(url) {
      let new_url = url.replace("http://", "").replace("https://", "");
      new_url = `https://${new_url}`;
      return new_url;
    },
    url() {
      window.open(
        `https://www.settrade.com/C04_01_stock_quote_p1.jsp?txtSymbol=${this.stock}`
      );
      // window.open(
      //   `https://www.set.or.th/set/companyprofile.do?symbol=${this.stock}`
      // );
    },
    url2() {
      return `https://th.tradingview.com/symbols/SET-${this.stock}/`;
    },
    loadStock() {
      // console.log(this.$store.getters.get_stock_choose_buy_data);

      if (this.stock != "") {
        this.$store.dispatch("reset_stock_state");
        this.$store.dispatch("load_stock_company_profile", this.stock);
        this.$store.dispatch("load_stock_name_data", this.stock);
        this.$store.dispatch("load_stock_quote_data", this.stock);
        this.$store.dispatch("load_stock_rightsandbenefit_data", this.stock);
        this.$store.dispatch("load_stock_historical_data", this.stock);
        this.$store.dispatch("load_stock_financial_data", this.stock);
        this.$store.dispatch("load_stock_data_success_data");

        setTimeout(() => {
          const label = [];

          const data = [];

          for (
            let i = 0;
            i < this.$store.getters.get_stock_historical_data.length;
            i++
          ) {
            label.push(i + 1);
            data.push(this.$store.getters.get_stock_historical_data[this.$store.getters.get_stock_historical_data.length - (i+1)][5]);
          }

          // console.log(data)
          
          const ctx = document.getElementById("myChart");
          new Chart(ctx, {
            type: "line",
            data: {
              labels: label,
              datasets: [
                {
                  label: "ราคา",
                  data: data,
                  backgroundColor: "rgba(71, 183,132,.5)",
                  borderColor: "#47b784",
                  borderWidth: 3,
                },
              ],
            },
            options: {
              responsive: true,
              lineTension: 1,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: false,
                      stepSize: 0,
                      padding: 25,
                    },
                  },
                ],
              },
            },
          });
        }, 3000);
      } else {
        Swal.fire({
          title: "กรุณากรอกชื่อหุ้น",
          icon: "warning",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          this.$refs.inputStock.select();
        }, 1800);
      }

      // this.$refs.inputStock.select();
    },
    save() {
      Swal.fire({
        title: "คุณต้องการบันทึกรายงานนี้",
        text: "บันทึกข้อมูล",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "บันทึกข้อมูล",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.autosave();
        }
      });

      // console.log(data);
    },
    autosave() {
      if (this.stock !== "") {
        let quote_percent =
          (this.$store.getters.get_stock_last_quote_data /
            (this.$store.getters.get_stock_historical_data.length > 0
              ? this.$store.getters.get_stock_historical_data[
                  this.$store.getters.get_stock_historical_data.length - 1
                ][5]
              : 0)) *
            100 -
          100;
        let date_xd = this.$store.getters.get_stock_date_xd;
        let company = this.stock;
        let should_buy = this.$store.getters.get_stock_choose_buy_data;
        let dividend_average = this.$store.getters.get_stock_rightsandbenefit_summary_div_avg_data.toFixed(
          5
        );
        let current_price = this.$store.getters.get_stock_last_quote_data;
        let price_to_buy = (
          this.$store.getters.get_stock_rightsandbenefit_summary_div_avg_data /
          0.07
        ).toFixed(2);
        let dividend =
          // this.$store.getters.get_stock_quote_financial_5_year_sum.toFixed(2);
          (
            (+this.$store.getters
              .get_stock_rightsandbenefit_summary_div_avg_data /
              +this.$store.getters.get_stock_quote_detail_data.Prior) *
            100
          ).toFixed(2);
        let range = this.$store.getters.get_stock_rightsandbenefit_r;
        let sd = this.$store.getters.get_stock_rightsandbenefit_sd;

        let data = {
          quote_percent: parseFloat(quote_percent),
          date_xd: date_xd,
          company: company,
          should_buy: parseFloat(should_buy),
          dividend_average: parseFloat(dividend_average),
          current_price: parseFloat(current_price),
          price_to_buy: parseFloat(price_to_buy),
          dividend: parseFloat(dividend),
          range: parseFloat(range),
          sd: parseFloat(sd),
        };

        this.$store.dispatch("load_stock_save_report_data", data);
      }
    },
  },
};
</script>
